<template>
  <main
    class="tourism color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  > 
    <!-- <TourismFilter :hasTypeDeal="false"></TourismFilter> -->
    <BannerSection location="homepage-top"></BannerSection>
    <div class="d-flex justify-content-between align-items-center mx-5">
      <h4>Flights</h4>
      <div class="d-flex mt-7 mobile-filter-buttons mb-5">
        <button 
          class="primary-btn d-flex align-items-center mx-2"
          @click="openFilterModal"
        >
          {{ $t('Search') }}
          <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img mx-5">
        </button>
      </div>
    </div>
    <MobileFilterModal :isFilterModalVisible.sync="isFilterModalVisible"></MobileFilterModal>
    <div class="tourism-container mt-5">
      <h4 class="mx-5 p-2">
        Cheapest flights
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px-5" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openMobileDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
            <router-link
              :to="{
                name: 'MobileFlightAll',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "Explore more Dates" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
      <h4 class="text-center p-2">
        View all Cheapest Flights
      </h4>
    </div>
    <div class="tourism-container mt-15">
      <h4 class="mx-5 p-2">
        <p>Last minute flights</p>
      </h4>
      <VueSlickCarousel
        v-bind:slidesToShow="itemPerSlide"
        class="cus-slick-carousel tourism-carousel"
        :class="deals.length<4 ? sliderDirection : ''"
        :rtl="sliderDirection=='rtl' ? true : false"
      >
        <div class="px-5" 
          v-for="(deal, index) in deals"
          :key="index"
        >
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7" 
              slot="actionPart" 
              @click="openMobileDetailModal()"
            >
              <img src="/assets/img/icons/info-white.svg" alt="" class="">
              {{ $t( "Full details" ) }}
            </button>
            <router-link
              :to="{
                name: 'MobileFlightAll',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "Explore more Dates" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </VueSlickCarousel>
      <h4 class="text-center p-2">
        View all last minutes flights
      </h4>
    </div>
    <MobileDetailModal :isOrder="false"></MobileDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../../components/BannerSection";
import TourismFilter from "../../../../components/tourism/TourismFilter.vue";
import FlightTourismItem from "../../../../components/tourism/flight/FlightTourismItem.vue";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import MobileFilterModal from "../../../../components/tourism/flight/MobileFilterModal.vue";

export default {
  name: "MobileFligntHome",
  components: {
    BannerSection,
    TourismFilter,
    FlightTourismItem,
    MobileDetailModal,
    MobileFilterModal
  },
  data() {
    return {
      deals: [{},{},{},{},{},{},{},{},{},{},{},{}],
      isFilterModalVisible: false
    }
  },
  methods: {
    openMobileDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";
</style>
